import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-551cdc8e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = ["href"]
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "right-align" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_icon = _resolveComponent("v-icon")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.relation.values, (resource, index) => {
    return (_openBlock(), _createElementBlock("tr", {
      key: index,
      class: "width-height-table"
    }, [
      (resource.length > 0)
        ? (_openBlock(), _createElementBlock("td", _hoisted_1, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sortedResource(resource), (gstGg, indexGstGg) => {
              return (_openBlock(), _createElementBlock("tr", { key: indexGstGg }, [
                _createElementVNode("td", null, [
                  (_ctx.getEntityRecordHref(_ctx.relation, gstGg))
                    ? (_openBlock(), _createElementBlock("a", {
                        key: 0,
                        class: "cursor-hover",
                        href: _ctx.getEntityRecordHref(_ctx.relation, gstGg)
                      }, _toDisplayString(gstGg[_ctx.relation.nameKey]), 9, _hoisted_2))
                    : (_openBlock(), _createElementBlock("a", _hoisted_3, _toDisplayString(gstGg[_ctx.relation.nameKey]), 1))
                ]),
                (_ctx.relation.label == 'gegevensgroepen' && !_ctx.disableEvtp)
                  ? (_openBlock(), _createBlock(_component_v_btn, {
                      key: 0,
                      width: "20",
                      height: "20",
                      class: "btn-relation",
                      icon: "mdi-close",
                      size: "x-small",
                      variant: "outlined",
                      onClick: () => _ctx.deleteGstGg(gstGg['gst_gg_cd'])
                    }, null, 8, ["onClick"]))
                  : (_ctx.relation.label == 'regelingen' && !_ctx.disableEvtp)
                    ? (_openBlock(), _createBlock(_component_v_btn, {
                        key: 1,
                        width: "20",
                        height: "20",
                        class: "btn-relation",
                        icon: "mdi-close",
                        size: "x-small",
                        variant: "outlined",
                        onClick: () => _ctx.deleteGstRge(gstGg['gst_rge_cd'])
                      }, null, 8, ["onClick"]))
                    : _createCommentVNode("", true)
              ]))
            }), 128))
          ]))
        : (_openBlock(), _createElementBlock("td", _hoisted_4, _toDisplayString(_ctx.messsageToBeFilled), 1)),
      _createElementVNode("td", _hoisted_5, [
        (_ctx.relation.label == 'gegevensstroom type' && !_ctx.disableEvtp && resource.length > 0)
          ? (_openBlock(), _createBlock(_component_v_btn, {
              key: 0,
              class: "btn-relation",
              icon: "mdi-close",
              size: "x-small",
              variant: "outlined",
              onClick: () => _ctx.deleteGstGstt(resource[0]['gst_gstt_cd'])
            }, null, 8, ["onClick"]))
          : _createCommentVNode("", true),
        (_ctx.relation.label == 'gegevensstroom type' && !_ctx.disableEvtp && resource.length == 0)
          ? (_openBlock(), _createBlock(_component_v_btn, {
              key: 1,
              color: "primary",
              variant: "outlined",
              to: {
          name: 'newEntityGstGsttWithRelation',
          params: {
            gstCd: _ctx.gstCd[index],
            recordResource: 'gst-gstt',
            versieNr: _ctx.versieNr
          },
          query: {
            redirect: _ctx.$route.fullPath
          }
        }
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_icon, null, {
                  default: _withCtx(() => [
                    _createTextVNode(" mdi-plus-box-outline ")
                  ]),
                  _: 1
                })
              ]),
              _: 2
            }, 1032, ["to"]))
          : _createCommentVNode("", true),
        (_ctx.relation.label == 'gegevensgroepen' && !_ctx.disableEvtp)
          ? (_openBlock(), _createBlock(_component_v_btn, {
              key: 2,
              color: "primary",
              variant: "outlined",
              to: {
          name: 'newEntityGstGgWithRelation',
          params: {
            gstCd: _ctx.gstCd[index],
            recordResource: 'gst-gg',
            versieNr: _ctx.versieNr
          },
          query: {
            redirect: _ctx.$route.fullPath
          }
        }
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_icon, null, {
                  default: _withCtx(() => [
                    _createTextVNode(" mdi-plus-box-outline ")
                  ]),
                  _: 1
                })
              ]),
              _: 2
            }, 1032, ["to"]))
          : (_ctx.relation.label == 'regelingen' && !_ctx.disableEvtp)
            ? (_openBlock(), _createBlock(_component_v_btn, {
                key: 3,
                color: "primary",
                variant: "outlined",
                to: {
          name: 'newEntityGstRgeWithRelation',
          params: {
            gstCd: _ctx.gstCd[index],
            recordResource: 'gst-rge',
            versieNr: _ctx.versieNr
          },
          query: {
            redirect: _ctx.$route.fullPath
          }
        }
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_icon, null, {
                    default: _withCtx(() => [
                      _createTextVNode(" mdi-plus-box-outline ")
                    ]),
                    _: 1
                  })
                ]),
                _: 2
              }, 1032, ["to"]))
            : _createCommentVNode("", true)
      ])
    ]))
  }), 128))
}