import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createBlock(_component_v_dialog, {
    "max-width": _ctx.maxWidthDialog,
    "no-click-animation": "",
    scrollable: "",
    "model-value": true,
    persistent: "",
    "onClick:outside": _cache[3] || (_cache[3] = ($event: any) => (_ctx.close()))
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card, {
        class: _normalizeClass({ 'no-horizontal-scroll': _ctx.childProps.tab !== 'relations' })
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_card_title, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$attrs.title), 1)
            ]),
            _: 1
          }),
          (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.component), _mergeProps(_ctx.childProps, {
            onConfirm: _cache[0] || (_cache[0] = ($event: any) => (_ctx.close())),
            onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.close())),
            onRecordUpdated: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('recordUpdated')))
          }), null, 16))
        ]),
        _: 1
      }, 8, ["class"])
    ]),
    _: 1
  }, 8, ["max-width"]))
}