import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "width-height-table" }
const _hoisted_2 = ["href"]
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "subtext" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("tr", _hoisted_1, [
    _createElementVNode("td", null, [
      _createElementVNode("tr", null, [
        _createElementVNode("a", {
          href: _ctx.getEntityRecordHref(_ctx.relation, _ctx.primaryKey),
          class: "cursor-hover"
        }, _toDisplayString(_ctx.relation.values[0][_ctx.nameKey]), 9, _hoisted_2)
      ]),
      (_ctx.relation.values[0].entity_omg)
        ? (_openBlock(), _createElementBlock("tr", _hoisted_3, [
            _createElementVNode("a", _hoisted_4, _toDisplayString(_ctx.relation.values[0].entity_omg.titel.substr(0, 70) +
            (_ctx.relation.values[0].entity_omg.titel.length > 70 ? '...' : '')), 1)
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}