import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_autocomplete = _resolveComponent("v-autocomplete")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_textarea = _resolveComponent("v-textarea")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_row = _resolveComponent("v-row")!

  return (_openBlock(), _createBlock(_component_v_row, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_col, null, {
        default: _withCtx(() => [
          (_ctx.foreignResourceModel)
            ? (_openBlock(), _createBlock(_component_v_autocomplete, {
                key: 0,
                modelValue: _ctx.selectedForeignRecord,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedForeignRecord) = $event)),
                items: _ctx.recordsList,
                loading: _ctx.loading,
                label: `Selecteer: ${_ctx.label || _ctx.fieldKey}` + (_ctx.required ? ' (verplicht)' : ''),
                disabled: _ctx.disableEvtp,
                density: "comfortable",
                variant: "outlined",
                "return-object": "",
                clearable: "",
                "hide-no-data": ""
              }, null, 8, ["modelValue", "items", "loading", "label", "disabled"]))
            : (_ctx.fieldKey === 'koepel')
              ? (_openBlock(), _createBlock(_component_v_select, {
                  key: 1,
                  modelValue: _ctx.selectedKoepel,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedKoepel) = $event)),
                  items: _ctx.koepelOptions,
                  disabled: _ctx.disableEvtp,
                  label: (_ctx.label || _ctx.fieldKey) + (_ctx.required ? ' (verplicht)' : ''),
                  density: "comfortable",
                  variant: "outlined"
                }, null, 8, ["modelValue", "items", "disabled", "label"]))
              : (_ctx.fieldKey == 'versie_nr')
                ? (_openBlock(), _createBlock(_component_v_textarea, {
                    key: 2,
                    "model-value": _ctx.getModelValue(),
                    label: (_ctx.label || _ctx.fieldKey) + (_ctx.required ? '' : ''),
                    density: "compact",
                    "persistent-hint": "",
                    variant: "outlined",
                    "persistent-placeholder": "",
                    disabled: "",
                    rows: "1"
                  }, null, 8, ["model-value", "label"]))
                : (_ctx.fieldKey === 'id_publicatiestatus')
                  ? (_openBlock(), _createBlock(_component_v_select, {
                      key: 3,
                      modelValue: _ctx.selectedIdPublicatiestatus,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectedIdPublicatiestatus) = $event)),
                      label: (_ctx.label || _ctx.fieldKey) + (_ctx.required ? ' (verplicht)' : ''),
                      density: "comfortable",
                      variant: "outlined",
                      disabled: ""
                    }, null, 8, ["modelValue", "label"]))
                  : (_openBlock(), _createBlock(_component_v_textarea, {
                      key: _ctx.fieldKey,
                      counter: _ctx.maximumValueLength,
                      rules: _ctx.rules,
                      label: (_ctx.label || _ctx.fieldKey) + (_ctx.required ? ' (verplicht)' : ''),
                      "model-value": _ctx.getModelValue(),
                      disabled: _ctx.readonly || _ctx.disableEvtp,
                      density: "compact",
                      "persistent-hint": "",
                      filled: "",
                      variant: "outlined",
                      "persistent-placeholder": "",
                      rows: "1",
                      "auto-grow": "",
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = 
          (v) => [(_ctx.value = v), !v && _ctx.isIntegerKey ? _ctx.$emit('update', null) : _ctx.$emit('update', v)]
        )
                    }, null, 8, ["counter", "rules", "label", "model-value", "disabled"]))
        ]),
        _: 1
      }),
      _createVNode(_component_v_col, { cols: "3" }, {
        default: _withCtx(() => [
          (_ctx.foreignResourceModel && _ctx.value && _ctx.foreignKey && _ctx.foreignResourceInClient)
            ? (_openBlock(), _createBlock(_component_v_btn, {
                key: 0,
                size: "small",
                icon: "mdi-link",
                variant: "outlined",
                color: "primary",
                class: "px-0 mr-2",
                to: {
          name: 'entityRecord',
          params: {
            tab: 'data',
            recordResource: _ctx.foreignResourceModel.resource,
            id: _ctx.value[_ctx.foreignKey.foreign_table.primary_key]
          }
        }
              }, null, 8, ["to"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}