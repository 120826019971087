import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_entity_record_data_field = _resolveComponent("entity-record-data-field")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_file_input = _resolveComponent("v-file-input")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_v_card_text, { class: "form-container" }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.includedFields, (fieldKey) => {
          return (_openBlock(), _createBlock(_component_entity_record_data_field, {
            key: fieldKey,
            "field-key": fieldKey,
            "original-key": _ctx.originalFieldKeys[fieldKey],
            label: _ctx.table.columns[_ctx.originalFieldKeys[fieldKey]],
            "initial-value": _ctx.inputValue[fieldKey],
            "table-name": _ctx.table.label,
            "field-properties": _ctx.tableModel.fields[fieldKey],
            "foreign-key": _ctx.getForeignKey(fieldKey),
            resource: _ctx.table.resource,
            required: _ctx.isRequired(_ctx.originalFieldKeys[fieldKey]),
            readonly: _ctx.isReadonly(_ctx.originalFieldKeys[fieldKey]),
            "new-relationevtp": _ctx.evtpTree,
            "data-type": _ctx.getDataType(_ctx.originalFieldKeys[fieldKey]),
            "disable-evtp": _ctx.disableEvtp,
            onUpdate: (v) => _ctx.updateRecord(fieldKey, v)
          }, null, 8, ["field-key", "original-key", "label", "initial-value", "table-name", "field-properties", "foreign-key", "resource", "required", "readonly", "new-relationevtp", "data-type", "disable-evtp", "onUpdate"]))
        }), 128)),
        (_ctx.table.resource == 'evtp-acc')
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createVNode(_component_v_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_divider),
                  _createVNode(_component_v_card_title, null, {
                    default: _withCtx(() => [
                      _createTextVNode(" Bijlage ")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_v_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_form, {
                        modelValue: _ctx.isFormValid,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.isFormValid) = $event))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_file_input, {
                            modelValue: _ctx.files,
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.files) = $event)),
                            counter: "",
                            multiple: "",
                            "show-size": "",
                            "small-chips": "",
                            density: "compact",
                            accept: ".pdf",
                            label: "Voer bestand(en) in",
                            rules: _ctx.fileInputRules
                          }, null, 8, ["modelValue", "rules"])
                        ]),
                        _: 1
                      }, 8, ["modelValue"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list_files, (file, index) => {
                return (_openBlock(), _createBlock(_component_v_row, { key: index }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_col, null, {
                      default: _withCtx(() => [
                        _createElementVNode("table", null, [
                          _createElementVNode("tr", {
                            style: {"color":"#01689b","text-decoration":"underline"},
                            onClick: () => _ctx.getFiles(file)
                          }, _toDisplayString(file), 9, _hoisted_1)
                        ])
                      ]),
                      _: 2
                    }, 1024),
                    _createVNode(_component_v_col, { cols: "3" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_btn, {
                          size: "small",
                          icon: "mdi-download",
                          variant: "outlined",
                          color: "primary",
                          class: "px-0 mr-2",
                          onClick: () => _ctx.getFiles(file)
                        }, null, 8, ["onClick"]),
                        _createVNode(_component_v_btn, {
                          size: "small",
                          icon: "mdi-delete",
                          variant: "outlined",
                          color: "red",
                          class: "px-0 mr-2",
                          onClick: () => _ctx.removeFiles(file)
                        }, null, 8, ["onClick"])
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1024))
              }), 128))
            ], 64))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_component_v_divider),
    _createVNode(_component_v_card_actions, null, {
      default: _withCtx(() => [
        _createVNode(_component_v_spacer),
        _createVNode(_component_v_btn, {
          color: "primary",
          onClick: _cache[2] || (_cache[2] = () => _ctx.$emit('close'))
        }, {
          default: _withCtx(() => [
            _createTextVNode(" Annuleren ")
          ]),
          _: 1
        }),
        _createVNode(_component_v_btn, {
          color: "primary",
          disabled: _ctx.invalidFields.length > 0,
          onClick: _cache[3] || (_cache[3] = () => _ctx.$emit('confirm', _ctx.adjustedRecord, _ctx.files))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.evtpTree ? 'Toevoegen nieuwe relatie' : _ctx.isNewRow ? 'Toevoegen' : 'Opslaan'), 1)
          ]),
          _: 1
        }, 8, ["disabled"])
      ]),
      _: 1
    })
  ], 64))
}