import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_footer = _resolveComponent("v-footer")!

  return (_openBlock(), _createBlock(_component_v_footer, {
    class: "footer",
    color: _ctx.colour,
    padless: ""
  }, null, 8, ["color"]))
}