import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderCms = _resolveComponent("HeaderCms")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_snackbar = _resolveComponent("v-snackbar")!
  const _component_v_main = _resolveComponent("v-main")!
  const _component_FooterCms = _resolveComponent("FooterCms")!
  const _component_v_app = _resolveComponent("v-app")!

  return (_openBlock(), _createBlock(_component_v_app, null, {
    default: _withCtx(() => [
      _createVNode(_component_HeaderCms),
      _createVNode(_component_v_main, null, {
        default: _withCtx(() => [
          _createVNode(_component_router_view),
          _createVNode(_component_v_snackbar, {
            modelValue: $options.getSnackbar,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($options.getSnackbar) = $event)),
            timeout: 2000,
            color: $options.getFeedbackMessageColor()
          }, {
            actions: _withCtx(() => [
              _createVNode(_component_v_btn, {
                variant: "text",
                onClick: $options.closeSnackbar
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_icon, null, {
                    default: _withCtx(() => [
                      _createTextVNode("mdi-window-close")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["onClick"])
            ]),
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString($options.getFeedbackMessage()) + " ", 1)
            ]),
            _: 1
          }, 8, ["modelValue", "color"])
        ]),
        _: 1
      }),
      _createVNode(_component_FooterCms)
    ]),
    _: 1
  }))
}