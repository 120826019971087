import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "text-left" }
const _hoisted_2 = {
  key: 7,
  color: "primary",
  class: "mx-2 table-button"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_row = _resolveComponent("v-row")!

  return (_openBlock(), _createElementBlock("tr", null, [
    _createElementVNode("td", _hoisted_1, [
      _createVNode(_component_v_row, {
        class: "centered",
        style: {"white-space":"nowrap","display":"inline-block"}
      }, {
        default: _withCtx(() => [
          (_ctx.id && _ctx.resource === 'evtp-version' && _ctx.currentVersion)
            ? (_openBlock(), _createBlock(_component_v_btn, {
                key: 0,
                color: "primary",
                class: "mx-2 table-button",
                flat: "",
                to: {
            name: 'adjustEvtpVersion',
            params: {
              id: _ctx.id,
              resource: _ctx.resource,
              recordResource: _ctx.resource,
              versieNr: _ctx.versieNr
            }
          }
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_tooltip, {
                    activator: "parent",
                    location: "top"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(" Wijzigen ")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_icon, null, {
                    default: _withCtx(() => [
                      _createTextVNode("mdi-pencil")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["to"]))
            : _createCommentVNode("", true),
          (_ctx.id && _ctx.resource !== 'evtp-version' && _ctx.currentVersion)
            ? (_openBlock(), _createBlock(_component_v_btn, {
                key: 1,
                color: "primary",
                class: "mx-2 table-button",
                flat: "",
                to: {
            name: 'entityRecord',
            params: {
              id: _ctx.id,
              resource: _ctx.resource,
              recordResource: _ctx.resource,
              tab: 'data'
            }
          }
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_tooltip, {
                    activator: "parent",
                    location: "top"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(" Wijzigen ")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_icon, null, {
                    default: _withCtx(() => [
                      _createTextVNode("mdi-pencil")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["to"]))
            : _createCommentVNode("", true),
          (['evtp-version'].includes(_ctx.resource))
            ? (_openBlock(), _createBlock(_component_v_btn, {
                key: 2,
                to: {
            name: 'newEvtpVersion',
            params: {
              id: _ctx.id,
              resource: _ctx.resource,
              recordResource: _ctx.resource,
              versieNr: _ctx.versieNr ? _ctx.versieNr + 1 : null
            }
          },
                color: "warning",
                flat: "",
                class: "mx-2 table-button"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_tooltip, {
                    activator: "parent",
                    location: "top"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(" Nieuwe versie aanmaken ")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_icon, null, {
                    default: _withCtx(() => [
                      _createTextVNode("mdi-table-multiple")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["to"]))
            : _createCommentVNode("", true),
          (['evtp-version'].includes(_ctx.resource))
            ? (_openBlock(), _createBlock(_component_v_btn, {
                key: 3,
                to: {
            name: 'duplicateEvtpVersion',
            params: {
              id: _ctx.id,
              resource: _ctx.resource,
              recordResource: _ctx.resource,
              versieNr: _ctx.versieNr ? _ctx.versieNr + 1 : null
            }
          },
                color: "green",
                flat: "",
                class: "mx-2 table-button"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_tooltip, {
                    activator: "parent",
                    location: "top"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(" Soortgelijke besluit maken ")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_icon, null, {
                    default: _withCtx(() => [
                      _createTextVNode("mdi-plus-box-multiple-outline")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["to"]))
            : _createCommentVNode("", true),
          (['gg', 'gg-koepel', 'oe', 'oe-koepel'].includes(_ctx.resource))
            ? (_openBlock(), _createBlock(_component_v_btn, {
                key: 4,
                to: {
            name: 'entityRecordRelations',
            params: {
              id: _ctx.id,
              resource: _ctx.resource,
              recordResource: _ctx.resource,
              tab: 'relations'
            }
          },
                color: "secondary",
                flat: "",
                class: "mx-2 table-button"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_tooltip, {
                    activator: "parent",
                    location: "top"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(['gg', 'gg-koepel'].includes(_ctx.resource)
                ? 'Relatie gegevensgroep'
                : ['oe', 'oe-koepel'].includes(_ctx.resource)
                ? 'Relatie organisatie'
                : ''), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_icon, null, {
                    default: _withCtx(() => [
                      _createTextVNode("mdi-arrow-up-down-bold-outline")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["to"]))
            : _createCommentVNode("", true),
          (['evtp-version'].includes(_ctx.resource))
            ? (_openBlock(), _createBlock(_component_v_btn, {
                key: 5,
                to: {
            name: 'entityEvtpStructure',
            params: {
              id: _ctx.id,
              resource: _ctx.resource,
              recordResource: _ctx.resource,
              tab: 'relations',
              versieNr: _ctx.versieNr
            }
          },
                color: "secondary",
                flat: "",
                class: "mx-2 table-button"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_tooltip, {
                    activator: "parent",
                    location: "top"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString('Besluitenboom structuur'))
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_icon, null, {
                    default: _withCtx(() => [
                      _createTextVNode("mdi-arrow-up-down-bold-outline")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["to"]))
            : _createCommentVNode("", true),
          (_ctx.id && !_ctx.idPublicatiestatusBool && _ctx.currentVersion)
            ? (_openBlock(), _createBlock(_component_v_btn, {
                key: 6,
                color: "error",
                class: "mx-2 table-button",
                flat: "",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.deleteObject(_ctx.id)))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_tooltip, {
                    activator: "parent",
                    location: "top"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(" Verwijderen ")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_icon, null, {
                    default: _withCtx(() => [
                      _createTextVNode("mdi-close")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (!_ctx.currentVersion)
            ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
                _createVNode(_component_v_tooltip, {
                  activator: "parent",
                  location: "top"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(" Oudere versie ")
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_icon, null, {
                  default: _withCtx(() => [
                    _createTextVNode(" mdi-file-hidden ")
                  ]),
                  _: 1
                })
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dataColumns, (dC) => {
      return (_openBlock(), _createElementBlock("td", { key: dC }, _toDisplayString(_ctx.getValue(dC)), 1))
    }), 128))
  ]))
}