import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EvptOnd = _resolveComponent("EvptOnd")!
  const _component_EvtpName = _resolveComponent("EvtpName")!
  const _component_GstOe = _resolveComponent("GstOe")!
  const _component_GgRge = _resolveComponent("GgRge")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.relation.resource == 'ond' || _ctx.relation.resource == 'oe-com-type')
      ? (_openBlock(), _createBlock(_component_EvptOnd, {
          key: 0,
          relation: _ctx.relation,
          "primary-key": _ctx.primaryKey,
          "name-key": _ctx.nameKey,
          "evtp-cd": _ctx.evtpCd,
          "versie-nr": _ctx.versieNr,
          "disable-evtp": _ctx.disableEvtp,
          onRecordUpdated: _cache[0] || (_cache[0] = () => _ctx.$emit('recordUpdated'))
        }, null, 8, ["relation", "primary-key", "name-key", "evtp-cd", "versie-nr", "disable-evtp"]))
      : _createCommentVNode("", true),
    (_ctx.relation.resource == 'evtp-version')
      ? (_openBlock(), _createBlock(_component_EvtpName, {
          key: 1,
          relation: _ctx.relation,
          "primary-key": _ctx.primaryKey,
          "name-key": _ctx.nameKey,
          "disable-evtp": _ctx.disableEvtp
        }, null, 8, ["relation", "primary-key", "name-key", "disable-evtp"]))
      : (_ctx.relation.resource == 'gst' || _ctx.relation.resource == 'oe')
        ? (_openBlock(), _createBlock(_component_GstOe, {
            key: 2,
            relation: _ctx.relation,
            "primary-key": _ctx.primaryKey,
            "name-key": _ctx.nameKey,
            "evtp-cd": _ctx.evtpCd,
            "versie-nr": _ctx.versieNr,
            "gst-gg-cd": _ctx.gstGgCd,
            "disable-evtp": _ctx.disableEvtp,
            onRecordUpdated: _cache[1] || (_cache[1] = () => _ctx.$emit('recordUpdated'))
          }, null, 8, ["relation", "primary-key", "name-key", "evtp-cd", "versie-nr", "gst-gg-cd", "disable-evtp"]))
        : (
      _ctx.relation.resource == 'gg' || _ctx.relation.resource == 'rge' || _ctx.relation.resource == 'gst-gstt'
    )
          ? (_openBlock(), _createBlock(_component_GgRge, {
              key: 3,
              relation: _ctx.relation,
              "primary-key": _ctx.primaryKey,
              "name-key": _ctx.nameKey,
              "gst-cd": _ctx.gstCd,
              "versie-nr": _ctx.versieNr,
              "disable-evtp": _ctx.disableEvtp,
              onRecordUpdated: _cache[2] || (_cache[2] = () => _ctx.$emit('recordUpdated'))
            }, null, 8, ["relation", "primary-key", "name-key", "gst-cd", "versie-nr", "disable-evtp"]))
          : _createCommentVNode("", true)
  ], 64))
}