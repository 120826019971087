import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1e67044d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"white-space":"nowrap"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_toolbar_title = _resolveComponent("v-toolbar-title")!
  const _component_v_toolbar = _resolveComponent("v-toolbar")!
  const _component_v_checkbox = _resolveComponent("v-checkbox")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("th", _hoisted_1, [
      _createTextVNode(_toDisplayString(_ctx.header) + " ", 1),
      _createVNode(_component_v_icon, {
        class: _normalizeClass(["th-icon", { 'th-icon-filter': !!_ctx.selectedFilters }]),
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.dialog = true))
      }, {
        default: _withCtx(() => [
          _createTextVNode(" mdi-filter ")
        ]),
        _: 1
      }, 8, ["class"])
    ]),
    _createVNode(_component_v_dialog, {
      modelValue: _ctx.dialog,
      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.dialog) = $event)),
      scrollable: "",
      "max-width": "600"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_card, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_toolbar, { color: "primary" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_toolbar_title, null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.header), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_v_card_text, null, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.values, (v) => {
                  return (_openBlock(), _createBlock(_component_v_checkbox, {
                    key: v,
                    modelValue: _ctx.checkedValues,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.checkedValues) = $event)),
                    "hide-details": "",
                    label: _ctx.labelKey && v ? v[_ctx.labelKey] : v,
                    multiple: true,
                    value: v,
                    density: "compact",
                    inline: true,
                    ma: "2"
                  }, null, 8, ["modelValue", "label", "value"]))
                }), 128))
              ]),
              _: 1
            }),
            _createVNode(_component_v_divider),
            _createVNode(_component_v_card_actions, null, {
              default: _withCtx(() => [
                (_ctx.checkedValues.length != 0)
                  ? (_openBlock(), _createBlock(_component_v_btn, {
                      key: 0,
                      color: "primary",
                      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.checkedValues = []))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(" Filters verwijderen ")
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                _createVNode(_component_v_spacer),
                _createVNode(_component_v_btn, {
                  color: "primary",
                  onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.dialog = false))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(" Sluiten ")
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_btn, {
                  color: "primary",
                  onClick: _cache[4] || (_cache[4] = ($event: any) => {;[_ctx.$emit('setFilter', _ctx.checkedValues), (_ctx.dialog = false)]})
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(" Toepassen ")
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue"])
  ], 64))
}